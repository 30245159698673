<template>
  <div class="uploadno">
    <el-card>

      <div slot="header" class="clearfix">

        <div style="margin-bottom:20px;">
          拣货回退
          <el-button @click="pushToPhonePage" size="mini">切换电脑版</el-button>
        </div>
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" @submit.native.prevent size="mini">
          <el-form-item label="快递单号" prop="mailNo">
            <el-input v-model="form.mailNo"></el-input>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button type="primary" @click="onSubmit" >拣货回退</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-card>




  </div>
</template>

<script>
import {packingback} from "@/services/logistics";
export default {
  name: 'packingback',
  data() {
    return {
      dialogDel: false,
      userInfo: {},
      mailNo: '',
      form: {

      },
    }
  },
  methods: {

    pushToPhonePage() {
      this.$router.push('/packingBack');
    },
    // 回退单号
    async onSubmit() {
      const {data} = await packingback(this.form)
      if (200 === data.code) {
        this.$message.success('操作成功')
        this.form = {}
      } else {
        this.$message.error('回退失败')
      }
    },



  },
  created() {

  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>

</style>
